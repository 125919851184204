import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-grid-system'
import Img from 'gatsby-image'
import { useInView } from 'react-intersection-observer'
import { useSpring, animated } from 'react-spring'
import { rhythm } from '../../../config/typography'
import { AnimSlowYIn } from '../../elements'

const RowItem = styled(Row)`
  flex-direction: ${props => props.flxdirection};
`
const ProfileTextColumn = ({ children, delay }) => {
  return (
    <Row>
      <Col>
        <AnimSlowYIn delay={delay}>
          <p
            style={{
              maxWidth: '50ch',
              margin: `${rhythm(0.25)} auto`,
              color: 'white',
            }}
          >
            {children}
          </p>
        </AnimSlowYIn>
      </Col>
    </Row>
  )
}

const ProfileCards = ({ profile }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  })
  const imageSpring = useSpring({
    clipPath: inView ? 'circle(50% at 50% 50%)' : 'circle(0% at 50% 50%)',
    WebkitClipPath: inView ? 'circle(50% at 50% 50%)' : 'circle(0% at 50% 50%)',
  })

  return (
    <Wrap>
      <RowItem
        align="center"
        justify="center"
        flxdirection={profile.node.frontmatter.flexDir}
      >
        <Col sm={4} xs={6}>
          <animated.div style={imageSpring}>
            <Img
              fluid={profile.node.frontmatter.headshot.childImageSharp.fluid}
            />
          </animated.div>
          <span ref={ref} />
        </Col>
        <Col sm={5} xs={10}>
          <Row align="start">
            <Name xs={12}>
              <AnimSlowYIn delay="100">
                <h1>{profile.node.frontmatter.name}</h1>
              </AnimSlowYIn>
            </Name>
          </Row>
          <Row align="start">
            <Title xs={12}>
              <AnimSlowYIn delay="375">
                <h6>Partner</h6>
              </AnimSlowYIn>
            </Title>
          </Row>
          <ProfileTextColumn delay="750">
            {profile.node.frontmatter.text}
          </ProfileTextColumn>
          <ProfileTextColumn delay="850">
            {profile.node.frontmatter.text2}
          </ProfileTextColumn>
          <ProfileTextColumn delay="950">
            {profile.node.frontmatter.textThree}
          </ProfileTextColumn>
        </Col>
      </RowItem>
    </Wrap>
  )
}

export default ProfileCards

const Wrap = styled.div`
  margin-bottom: ${rhythm(1)};
  padding-top: ${rhythm(1.5)};
  padding-bottom: ${rhythm(1.5)};
  &:nth-of-type(3) {
    margin-top: ${rhythm(1)};
  }
`

const Name = styled(Col)`
  z-index: 11;
  white-space: nowrap;
  color: ${props => props.theme.brand.primary};
  text-transform: uppercase;
  margin-top: ${rhythm(1.25)};
  h1 {
    margin: auto;
    text-align: left;
    font-size: ${rhythm(1.25)};
    color: white;
  }
`

const Title = styled(Col)`
  z-index: 12;

  text-transform: uppercase;

  h6 {
    color: ${props => props.theme.colors.midgrey};
    font-size: ${rhythm(0.9)};
    text-align: left;
    &:after {
      content: '';
      height: 2px;
      width: 100%;
      background: ${props => props.theme.colors.orangered};
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -5;
      margin-bottom: ${rhythm(-0.5)};
    }
  }
`
